import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { SiteData } from "../../Constants/siteData";

export const ArboristConsultingContent = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Our Arborist Consulting Services</Heading>
        <Text>
          At West Valley Tree Care, we are dedicated to providing comprehensive
          consulting services to address various aspects of tree care and
          management. Our services include:
        </Text>
        <UnorderedList spacing={3}>
        <ListItem>
            <b>Tree Health Assessments:</b> Our certified arborists conduct
            thorough evaluations to determine the health of your trees and
            identify potential issues. Through meticulous examination and
            diagnostic techniques, we assess the overall health and condition
            of your trees, identifying signs of disease, pest infestation,
            structural weakness, or other concerns that may impact their
            vitality and longevity.
        </ListItem>
        <ListItem>
            <b>Tree Care Recommendations:</b> Receive expert advice on pruning,
            fertilization, pest control, and other maintenance practices to keep
            your trees healthy and thriving. Based on our assessment findings
            and your specific tree care goals, we provide tailored
            recommendations and proactive management strategies to address
            identified issues, promote optimal growth, and enhance the
            resilience of your trees.
        </ListItem>
        <ListItem>
            <b>Landscape Planning:</b> We offer assistance in planning and
            managing your landscape to ensure the right tree is planted in the
            right place. Drawing on our expertise in arboriculture and
            landscape design, we collaborate with you to develop strategic
            planting plans that optimize aesthetics, functionality, and
            environmental sustainability, ensuring a harmonious and
            well-balanced landscape.
        </ListItem>
        <ListItem>
            <b>Risk Assessment:</b> Identify potential hazards related to trees
            on your property, and our experts will recommend appropriate
            measures to mitigate risks. Through comprehensive risk assessments,
            we identify and evaluate potential hazards such as unstable
            branches, tree decay, or proximity to structures, providing
            actionable recommendations and risk management solutions to
            enhance safety and minimize liability.
        </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Why Choose Our Arborist Consulting?</Heading>
        <Text>
          Choose West Valley Tree Care for your arborist consulting needs. Our
          certified arborists bring a wealth of knowledge and experience to
          every consultation. Here's why we are the go-to choice:
        </Text>
        <UnorderedList spacing={3}>
        <ListItem>
            <b>Certified and Experienced:</b> Our team consists of certified
            arborists with extensive experience in tree biology, care, and
            management. With professional certification and years of hands-on
            experience, our arborists possess the expertise and skillset
            necessary to provide superior tree care services tailored to the
            unique needs of your landscape.
        </ListItem>
        <ListItem>
            <b>Personalized Approach:</b> We provide tailored recommendations
            based on the unique needs of your trees and landscape. By
            understanding the specific characteristics and requirements of
            your trees, soil, and environmental conditions, we develop
            customized solutions that optimize health, growth, and resilience,
            ensuring long-term sustainability and beauty.
        </ListItem>
        <ListItem>
            <b>Comprehensive Knowledge:</b> Our arborists stay updated on the
            latest research and techniques in arboriculture to offer the best
            advice. Continual professional development and a commitment to
            ongoing education ensure that our team remains at the forefront
            of industry advancements, allowing us to apply the most effective
            and innovative practices to address your tree care needs.
        </ListItem>
        <ListItem>
            <b>Commitment to Sustainability:</b> At West Valley Tree Care, we
            emphasize eco-friendly practices and sustainable landscape
            management in all our recommendations. From organic fertilization
            methods to integrated pest management strategies, we prioritize
            environmental stewardship and conservation, striving to minimize
            our ecological footprint while promoting the health and vitality
            of your trees and surrounding ecosystem.
        </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Schedule Your Arborist Consultation Today</Heading>
        <Text>
          Ready to enhance the health and beauty of your trees? Contact us at{" "}
          <Link href={SiteData.telLink}>{SiteData.phoneNumberWithDashes}</Link>{" "}
          to schedule an arborist consultation and discover how West Valley Tree
          Care can help you achieve a thriving landscape.
        </Text>
      </Stack>
    </Box>
  );
};
