export const posts = [
    {
        id: '1',
        title: 'The Ultimate Guide to Tree Pruning in West Valley City',
        metaTitle: 'Your Comprehensive Guide to Tree Pruning in West Valley City | West Valley City Tree Care Blog',
        excerpt: 'Are you looking to understand tree pruning and its importance better? This article shares the ultimate guide for West Valley City homeowners.',
        slug: 'ultimate-guide-to-tree-pruning-in-west-valley-city',
        tags: ['tree pruning', 'guide', 'West Valley City'],
        metaDescription: 'Curious about tree pruning in West Valley City? Discover our professional guide to keep your trees in optimal health and enhance their aesthetic appeal.',
        lastModified: new Date('2023-12-03').toISOString()
    },
    {
        id: '2',
        title: 'The Essential Steps to Safe Tree Removal in West Valley City',
        metaTitle: 'How to Safely Remove a Tree in West Valley City | West Valley City Tree Care Blog',
        excerpt: 'Removing a tree can sometimes be a necessity. This article provides essential steps to safe and efficient tree removal for West Valley City homeowners.',
        slug: 'essential-steps-to-safe-tree-removal-in-west-valley-city',
        tags: ['tree removal', 'safety', 'West Valley City'],
        metaDescription: 'Learn the vital steps to safe and efficient tree removal in West Valley City with our professional guide.',
        lastModified: new Date('2023-12-15').toISOString()
    },
    {
        id: '3',
        title: 'Benefits of Professional Tree Trimming in West Valley City',
        metaTitle: 'Why You Should Consider Professional Tree Trimming in West Valley City | West Valley City Tree Care Blog',
        excerpt: 'Considering tree trimming for your property? Discover the benefits of professional tree trimming services in West Valley City with our latest blog post.',
        slug: 'benefits-of-professional-tree-trimming-in-west-valley-city',
        tags: ['tree trimming', 'benefits', 'West Valley City'],
        metaDescription: 'Understand the significant benefits of professional tree trimming in West Valley City with our insightful blog post.',
        lastModified: new Date('2023-12-20').toISOString()
    },
    {
        id: '4',
        title: 'Understanding the Process of Stump Grinding in West Valley City',
        metaTitle: 'Your Guide to Stump Grinding in West Valley City | West Valley City Tree Care Blog',
        excerpt: 'Have an unsightly stump in your yard? Learn about the process of stump grinding in West Valley City, TN, and why it can be crucial for your property.',
        slug: 'understanding-stump-grinding-in-west-valley-city',
        tags: ['stump grinding', 'process', 'West Valley City'],
        metaDescription: 'Discover the process and benefits of stump grinding in West Valley City with our comprehensive blog post.',
        lastModified: new Date('2024-1-04').toISOString()
    },
]
