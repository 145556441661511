import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { SiteData } from "../../Constants/siteData";

export const EmergencyTreeService = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Expert Arborist Consulting Services</Heading>
        <Text>
          Discover a comprehensive range of consulting services at West Valley
          Tree Care designed to address various aspects of tree care and
          management. Our specialized services include:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
              <b>Tree Health Assessments:</b> We conduct thorough evaluations to
              determine the health of your trees and identify potential issues,
              ensuring their vitality. Through comprehensive inspections and
              diagnostic assessments, we assess the overall health and condition
              of your trees, identifying signs of disease, pest infestation,
              structural weakness, or other concerns that may impact their
              long-term health and sustainability.
          </ListItem>
          <ListItem>
              <b>Tree Care Recommendations:</b> Receive expert advice on pruning,
              fertilization, pest control, and other maintenance practices to keep
              your trees healthy and thriving year-round. Based on our assessment
              findings and your specific tree care goals, we provide tailored
              recommendations and proactive management strategies to address
              identified issues, promote optimal growth, and enhance the resilience
              of your trees throughout the changing seasons.
          </ListItem>
          <ListItem>
              <b>Landscape Planning:</b> Our arborists provide assistance in
              planning and managing your landscape, ensuring the right tree is
              planted in the optimal location for sustained growth. Drawing on our
              expertise in arboriculture and landscape design, we collaborate
              with you to develop strategic planting plans that optimize aesthetics,
              functionality, and environmental sustainability, ensuring a
              harmonious and well-balanced landscape for years to come.
          </ListItem>
          <ListItem>
              <b>Risk Assessment:</b> Identify potential hazards related to trees
              on your property, and our experts will recommend and implement
              appropriate measures to mitigate risks effectively. Through
              comprehensive risk assessments, we identify and evaluate potential
              hazards such as unstable branches, tree decay, or proximity to
              structures, providing actionable recommendations and risk management
              solutions to enhance safety and minimize liability on your property.
          </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Why Choose West Valley Tree Care for Arborist Consulting?
        </Heading>
        <Text>
          Our certified arborists bring a wealth of knowledge and experience to
          every consultation, making West Valley Tree Care the trusted choice
          for arborist services. Here's why our service stands out:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
              <b>Certified and Experienced:</b> Rely on our team of certified
              arborists with extensive experience in tree biology, care, and
              management. Our certified arborists bring a wealth of knowledge
              and expertise to every project, ensuring that your trees receive
              the highest standard of care and attention to detail.
          </ListItem>
          <ListItem>
              <b>Personalized Approach:</b> Benefit from our tailored
              recommendations based on the unique needs of your trees and
              landscape, ensuring optimal health and beauty. We understand that
              every landscape is unique, and we take a personalized approach
              to address your specific concerns and goals, providing
              individualized solutions that meet your expectations and exceed
              your satisfaction.
          </ListItem>
          <ListItem>
              <b>Comprehensive Knowledge:</b> Stay ahead with our arborists who
              stay updated on the latest research and techniques in arboriculture,
              providing you with the best and most advanced advice. Our arborists
              are dedicated to continual learning and professional development,
              ensuring that we remain at the forefront of industry advancements
              and equipped with the latest tools and knowledge to serve you better.
          </ListItem>
          <ListItem>
              <b>Commitment to Sustainability:</b> Choose eco-friendly practices
              and sustainable landscape management with our recommendations,
              contributing to a greener environment. We are committed to
              environmental stewardship and strive to incorporate sustainable
              practices into all aspects of our work, helping you create and
              maintain a healthy and vibrant landscape while minimizing
              ecological impact.
          </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Schedule Your Arborist Consultation Today</Heading>
        <Text>
          Ready to enhance the health and beauty of your trees? Contact West
          Valley Tree Care at{" "}
          <Link href={SiteData.telLink}>{SiteData.phoneNumberWithDashes}</Link>{" "}
          to schedule an arborist consultation. Discover how our expertise can
          help you achieve a thriving and picturesque landscape.
        </Text>
      </Stack>
    </Box>
  );
};
