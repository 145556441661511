import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { SiteData } from "../../Constants/siteData";

export const TreeRemovalContent = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Professional Tree Removal Services</Heading>
        <Text>
          At West Valley Tree Care, we offer comprehensive tree removal services
          to ensure the safety and visual appeal of your property. Here's an
          overview of what we provide:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
              <b>Dangerous Tree Removal:</b> Safely removing trees that pose a
              threat to your property or surroundings, reducing risks and ensuring
              safety. Our team specializes in identifying and safely eliminating
              trees that may pose hazards due to structural instability, proximity
              to buildings, or other factors, ensuring the safety of your property
              and those around it.
          </ListItem>
          <ListItem>
              <b>Dead Tree Removal:</b> Efficiently removing dead trees to enhance
              the health and aesthetics of your landscape. Dead trees not only detract
              from the beauty of your landscape but can also pose safety hazards as
              they deteriorate. Our experienced arborists swiftly and safely remove
              dead trees, restoring vitality and safety to your outdoor space.
          </ListItem>
          <ListItem>
              <b>Diseased Tree Removal:</b> Identifying and removing diseased
              trees to prevent the spread of illness to other plants and protect
              overall landscape health. Our team conducts thorough assessments to
              identify trees afflicted with diseases and takes prompt action to
              remove them, preventing the spread of pathogens and safeguarding the
              health of your entire landscape.
          </ListItem>
          <ListItem>
              <b>Obstructive Tree Removal:</b> Clearing space for your planned
              projects by removing trees that obstruct construction or renovation.
              Whether you're planning a new construction project or landscaping
              renovation, our team efficiently removes obstructive trees, creating
              space and clearing the way for your vision to become a reality.
          </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Why Choose West Valley Tree Care for Your Tree Removal Needs?
        </Heading>
        <Text>
          With extensive local experience and a track record of customer
          satisfaction, here's why West Valley Tree Care should be your first
          choice:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
              <b>Expert Arborists:</b> Our team of professional arborists has the
              right skills and knowledge to perform tree removal services safely
              and efficiently. With years of experience and ongoing training,
              our arborists are equipped to handle various tree removal scenarios
              while prioritizing safety and the health of your landscape.
          </ListItem>
          <ListItem>
              <b>Quality Service:</b> We deliver high-quality services with a
              commitment to customer satisfaction. Our track record and customer
              reviews stand as proof! From initial assessment to project completion,
              we strive for excellence in every aspect of our tree removal services,
              ensuring that our customers receive the best possible experience and
              results.
          </ListItem>
          <ListItem>
              <b>Local Experts:</b> As experts in local botany, shrubbery, and
              landscape, we offer specialized services tailored to the specific
              needs of your property. Our deep understanding of local flora and
              environmental conditions enables us to provide personalized solutions
              that promote the health and beauty of your landscape while respecting
              its unique characteristics.
          </ListItem>
          <ListItem>
              <b>Customer Satisfaction:</b> Our top priority is to make the
              process as smooth as possible, ensuring that our customers are
              completely satisfied with our services. From the moment you contact
              us to the completion of the project, we prioritize clear communication,
              transparency, and responsiveness to ensure a positive and satisfying
              experience for every customer.
          </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Top-notch Tree Removal Services in West Valley City, UT
        </Heading>
        <Text>
          Contact us today at{" "}
          <Link href={SiteData.telLink}>{SiteData.phoneNumberWithDashes}</Link>{" "}
          to schedule your tree removal service. Discover the West Valley Tree
          Care difference!
        </Text>
      </Stack>
    </Box>
  );
};
