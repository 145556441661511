import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { SiteData } from "../../Constants/siteData";

export const StumpGrindingContent = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Professional Stump Grinding Services</Heading>
        <Text>
          At West Valley Tree Care, we offer a comprehensive suite of stump
          grinding services designed to help you reclaim your landscape. Explore
          our services:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
              <b>Stump Grinding:</b> Our expert team can efficiently grind any
              stump, regardless of its size or location, reducing it below ground
              level effectively. Using specialized equipment and techniques, we
              grind stumps to eliminate tripping hazards, reclaim space, and
              facilitate seamless landscaping renovations.
          </ListItem>
          <ListItem>
              <b>Stump Debris Removal:</b> After grinding, we ensure thorough
              cleanup and removal of stump debris, leaving your property clean and
              tidy. Our team meticulously clears away all remnants of the stump,
              ensuring a pristine landscape and eliminating any trace of the
              previous obstruction.
          </ListItem>
          <ListItem>
              <b>Landscape Restoration:</b> Post-grinding, take advantage of our
              restoration services, including soil preparation and replanting if
              desired, to rejuvenate the area. We offer comprehensive landscape
              restoration solutions to transform the area previously occupied by
              the stump, preparing the soil and facilitating the growth of new
              vegetation to enhance the beauty and functionality of your landscape.
          </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Why Choose West Valley Tree Care for Your Stump Grinding Needs?
        </Heading>
        <Text>
          With a deep understanding of local landscaping needs and a proven
          track record, West Valley Tree Care is the right choice for your stump
          grinding needs. Here's why:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
              <b>Experienced Professionals:</b> Our team of professionals
              possesses the skills and equipment necessary to handle any stump
              grinding job efficiently. With years of experience and expertise
              in the field, our professionals are adept at tackling stump
              grinding tasks of varying complexities, delivering results that
              exceed expectations.
          </ListItem>
          <ListItem>
              <b>Quality Service:</b> We are committed to providing high-quality
              services that meet your satisfaction. Our reputation and positive
              customer reviews attest to this commitment! From start to finish,
              we prioritize excellence in service delivery, ensuring that each
              project is executed with precision, professionalism, and attention
              to detail to achieve optimal outcomes.
          </ListItem>
          <ListItem>
              <b>Local Expertise:</b> Leveraging our team's knowledge of the local
              terrain and botany, we provide tailored stump grinding solutions for
              your property. By understanding the unique characteristics of the
              local environment, we can offer customized stump grinding services
              that address the specific needs and challenges of your landscape,
              ensuring efficient and effective results.
          </ListItem>
          <ListItem>
              <b>Customer Satisfaction:</b> Ensuring a smooth, hassle-free
              experience for our customers is our top priority, resulting in their
              complete satisfaction with our services. From initial consultation
              to project completion, we strive to exceed customer expectations
              by delivering exceptional service, clear communication, and prompt
              resolution of any concerns, ensuring a positive and fulfilling
              experience for every client.
          </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Experience Superior Stump Grinding Services in West Valley City, UT
        </Heading>
        <Text>
          Contact West Valley Tree Care today at{" "}
          <Link href={SiteData.telLink}>{SiteData.phoneNumberWithDashes}</Link>{" "}
          to schedule your stump grinding service and experience the West Valley
          Tree Care difference!
        </Text>
      </Stack>
    </Box>
  );
};
