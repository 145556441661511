import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { SiteData } from "../../Constants/siteData";

export const LandClearingContent = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Professional Land Clearing Services</Heading>
        <Text>
          At West Valley Tree Care, we specialize in providing efficient land
          clearing solutions to prepare your property for its next phase. Our
          comprehensive services include:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
              <b>Tree and Brush Removal:</b> Safely and effectively remove trees,
              shrubs, and undergrowth, ensuring a clear site for your project.
              Our experienced team utilizes specialized equipment and techniques
              to carefully remove unwanted vegetation while minimizing
              disruption to the surrounding landscape, creating a clean and
              spacious environment for your development endeavors.
          </ListItem>
          <ListItem>
              <b>Stump Grinding and Removal:</b> After tree removal, we offer
              stump grinding services to leave your land smooth and ready for
              development. Using advanced grinding machinery, we efficiently
              grind tree stumps below ground level, eliminating tripping hazards
              and obstructions, and preparing the ground for landscaping or
              construction projects.
          </ListItem>
          <ListItem>
              <b>Debris Clearing and Disposal:</b> Handle the cleanup and
              responsible disposal of all cleared vegetation and debris, leaving
              your land ready for the next steps. Our team ensures thorough
              removal and disposal of all cleared materials, adhering to
              environmentally conscious practices to minimize waste and
              maximize efficiency, leaving your property clean, tidy, and
              primed for further development.
          </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Why Choose West Valley Tree Care for Land Clearing?
        </Heading>
        <Text>
          Choose West Valley Tree Care for your land clearing needs. Our
          expertise in land clearing sets us apart, and here's why:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
              <b>Comprehensive Approach:</b> We offer a full suite of services,
              from initial assessment to final clearing, tailored to your
              project's specific requirements. Our integrated approach ensures
              that every aspect of your land clearing project is carefully
              planned and executed, providing seamless coordination and
              consistent quality from start to finish.
          </ListItem>
          <ListItem>
              <b>Eco-Friendly Practices:</b> Our methods prioritize the
              environment, ensuring minimal impact and sustainable land use
              practices. We are committed to minimizing disruption to natural
              ecosystems and preserving biodiversity by employing environmentally
              friendly techniques and adhering to best practices in land
              management and conservation.
          </ListItem>
          <ListItem>
              <b>Professional Equipment:</b> Utilizing state-of-the-art machinery
              and techniques, we deliver efficient and safe land clearing
              services. Our investment in advanced equipment and technology
              enables us to complete projects with precision and efficiency,
              minimizing downtime and maximizing productivity while ensuring
              the safety of our team and the surrounding environment.
          </ListItem>
          <ListItem>
              <b>Experienced Team:</b> Our skilled professionals are trained in
              the latest land clearing methods, ensuring high-quality work for
              your project. With years of hands-on experience and a commitment
              to ongoing training and professional development, our team
              possesses the expertise and proficiency to tackle land clearing
              challenges of any scale or complexity, delivering superior
              results that meet and exceed client expectations.
          </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Start Your Land Clearing Project with West Valley Tree Care
        </Heading>
        <Text>
          Contact West Valley Tree Care at{" "}
          <Link href={SiteData.telLink}>{SiteData.phoneNumberWithDashes}</Link>{" "}
          for a consultation or to schedule professional land clearing services.
          Trust us for reliable, high-quality land clearing services in West
          Valley City, UT.
        </Text>
      </Stack>
    </Box>
  );
};
