import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { SiteData } from "../../Constants/siteData";

export const TreePruningContent = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h3"}>Expert Tree Pruning Services</Heading>
        <Text>
          At West Valley Tree Care, we are dedicated to delivering top-tier tree
          pruning services at competitive prices. Let's explore what sets us
          apart:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Health Pruning:</b> Our professionals identify and remove
            branches that are dead, diseased, or infested by pests, halting the
            spread of harmful agents and bolstering overall tree health.
          </ListItem>
          <ListItem>
            <b>Structural Pruning:</b> By targeting problems at the root and the
            base, we prune your trees to foster a robust structure that
            mitigates storm damage and reduces the likelihood of branch failure.
          </ListItem>
          <ListItem>
            <b>Aesthetic Pruning:</b> Trees don't just support the environment
            and human health – they're a treat for the eyes too. We shape and
            trim your trees to enhance their inherent beauty and augment your
            property's overall aesthetic appeal.
          </ListItem>
          <ListItem>
            <b>Safety Pruning:</b> Our team removes branches that could
            potentially harm your property, ensuring your landscape remains a
            safe haven.
          </ListItem>
        </UnorderedList>
      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h3"}>
          Why Choose West Valley Tree Care for Your Tree Pruning Needs?
        </Heading>
        <Text>
          With a deep-rooted understanding of the West Valley City area and a
          proven track record in tree pruning, here’s why we are your best
          choice:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
            <b>Expert Arborists:</b> Our team of highly skilled arborists has
            the knowledge and expertise necessary to prune your trees
            effectively and safely.
          </ListItem>
          <ListItem>
            <b>Quality Service:</b> We prioritize delivering high-quality
            service, and our customer reviews and impressive track record are a
            testament to this commitment!
          </ListItem>
          <ListItem>
            <b>Local Experts:</b> Our team members are intimately familiar with
            the local region's botany, shrubs, and landscapes, enabling us to
            provide specialized and targeted approaches that you won't find
            elsewhere.
          </ListItem>
          <ListItem>
            <b>Customer Satisfaction:</b> We're dedicated to ensuring our
            customers are happy and satisfied, and we commit to making the
            process as seamless as possible.
          </ListItem>
        </UnorderedList>
      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h3"}>
          Premium Tree Pruning Services in West Valley City, UT
        </Heading>
        <Text>
          Contact us today at{" "}
          <Link href={SiteData.telLink}>{SiteData.phoneNumberWithDashes}</Link>{" "}
          to schedule your tree pruning service. Experience the West Valley Tree
          Care difference!
        </Text>
      </Stack>
    </Box>
  );
};
