import {
  Box,
  Heading,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";

export const TreePlanting = () => {
  return (
    <Box>
      <Stack spacing={3}>
        <Text>
          Planting a tree is more than just enhancing the aesthetics of your
          landscape; it's an investment in the environment and future. At West
          Valley Tree Care, we specialize in tree planting services designed for
          the unique climate and soil conditions of West Valley City, UT. Our
          expert team will assist you in choosing the right trees for your
          property and ensure they are planted correctly for optimal growth and
          health.
        </Text>
        <Heading as={"h2"}>Why Opt for Professional Tree Planting?</Heading>
        <UnorderedList>
          <ListItem>
              <b>Correct Planting Techniques:</b> Ensuring trees are planted at
              the right depth and with proper spacing is crucial for their
              survival and growth. Our team employs industry-best practices to
              ensure that trees are planted with precision, taking into account
              factors such as soil composition, drainage, and sun exposure to
              promote healthy root development and long-term vitality.
          </ListItem>
          <ListItem>
              <b>Selection of Suitable Species:</b> We help select tree species
              that will thrive in the local climate and soil conditions of West
              Valley City. Drawing on our extensive knowledge of native and
              adaptive species, we recommend trees that are well-suited to the
              unique environmental conditions of your area, ensuring optimal
              growth and resilience.
          </ListItem>
          <ListItem>
              <b>Future Growth Planning:</b> Our experts consider the future
              growth of the tree, ensuring it won't interfere with structures,
              power lines, or other plants. By carefully assessing site
              conditions and anticipating growth patterns, we strategically
              position trees to minimize potential conflicts and maximize their
              long-term impact and aesthetic value in your landscape.
          </ListItem>
        </UnorderedList>

        <Heading as={"h2"}>Our Tree Planting Process</Heading>
        <Text>
          We take a comprehensive approach to tree planting, which includes:
        </Text>
        <UnorderedList>
          <ListItem>
              <b>Site Assessment:</b> Evaluating the planting site for sunlight,
              soil type, and drainage. Our thorough site assessments ensure
              that the chosen location is conducive to healthy tree growth,
              taking into consideration factors such as sunlight exposure,
              soil composition, and water drainage to optimize conditions for
              successful establishment.
          </ListItem>
          <ListItem>
              <b>Tree Selection:</b> Advising on the best tree species based on
              your aesthetic goals and the site's conditions. Drawing on our
              expertise in arboriculture and local ecology, we recommend tree
              species that are well-suited to the site's environmental
              conditions and complement your desired landscape aesthetics,
              ensuring a harmonious and sustainable planting scheme.
          </ListItem>
          <ListItem>
              <b>Professional Planting:</b> Using the best practices to ensure
              your new trees have a healthy start. Our team employs industry-
              leading planting techniques and equipment to ensure proper
              installation and root establishment, promoting strong and
              resilient tree growth from the outset.
          </ListItem>
          <ListItem>
              <b>Post-Planting Care:</b> Providing guidance on watering, mulching,
              and ongoing care for the newly planted trees. We offer comprehensive
              post-planting care instructions and support to help nurture your
              newly planted trees through their critical establishment phase,
              including guidance on watering schedules, mulching techniques, and
              proactive monitoring to ensure optimal health and growth.
          </ListItem>
        </UnorderedList>

        <Text>
          Contact West Valley Tree Care today to learn more about our tree
          planting services and how we can help enhance your property's
          landscape.
        </Text>
      </Stack>
    </Box>
  );
};
