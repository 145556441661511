import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { SiteData } from "../../Constants/siteData";

export const TreeHealthConsultingContent = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Expert Tree Health Consulting Services</Heading>
        <Text>
          At West Valley Tree Care, we are dedicated to ensuring the vitality
          and longevity of your trees. Explore our comprehensive tree health
          consulting services:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
              <b>Health Assessment:</b> Our team of certified arborists conducts
              thorough inspections to identify signs of disease, pest infestation,
              or other potential problems affecting your trees. Through meticulous
              examination and assessment, we detect early warning signs of
              ailments or stressors, allowing us to implement timely interventions
              to preserve the health and vitality of your trees.
          </ListItem>
          <ListItem>
              <b>Nutrition Plan:</b> We develop personalized nutrition plans
              tailored to your specific trees, ensuring they receive all the
              essential nutrients for healthy growth and development. By
              understanding the unique nutritional requirements of your trees
              and the soil conditions, we design comprehensive nutrition plans
              that optimize their health and resilience, promoting robust growth
              and longevity.
          </ListItem>
          <ListItem>
              <b>Disease and Pest Management:</b> Receive expert advice and
              services for managing tree diseases and pest infestations,
              protecting and preserving the health of your trees. Our team
              offers effective solutions for diagnosing and treating various
              tree ailments and pest infestations, employing environmentally
              friendly practices to restore and maintain the health of your
              valuable arboreal assets.
          </ListItem>
          <ListItem>
              <b>Tree Care Education:</b> We provide educational resources on tree
              care practices, empowering you to better understand how to maintain
              the health of your trees between professional services. Through
              informative materials and guidance, we equip you with the knowledge
              and tools to implement proper tree care practices, fostering a
              collaborative approach to tree health and ensuring long-term
              vitality and beauty for your landscape.
          </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Why Choose West Valley Tree Care for Your Tree Health Consulting
          Needs?
        </Heading>
        <Text>
          With deep roots in the community and a proven track record, West
          Valley Tree Care is the ideal choice for your tree health consulting
          needs. Here's why:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
              <b>Certified Arborists:</b> Our team of certified arborists brings a
              wealth of knowledge and expertise to every consultation, ensuring
              the highest standards of tree care. With professional accreditation
              and ongoing training, our arborists possess the skills and
              qualifications necessary to assess, diagnose, and address a wide
              range of tree health issues with precision and care.
          </ListItem>
          <ListItem>
              <b>Customized Solutions:</b> We provide personalized solutions for
              your unique tree care needs, ensuring optimal health and growth for
              your trees. By taking into account factors such as tree species,
              environmental conditions, and your specific preferences, we tailor
              our services to address the individual requirements of each tree,
              promoting resilience, vitality, and long-term sustainability.
          </ListItem>
          <ListItem>
              <b>Local Knowledge:</b> Our team's familiarity with the local flora
              equips us to handle your tree health concerns effectively. Drawing
              on our deep understanding of regional climate patterns, soil
              compositions, and indigenous tree species, we offer informed
              recommendations and solutions that are tailored to the unique
              characteristics of your landscape, ensuring optimal outcomes for
              your trees and surrounding environment.
          </ListItem>
          <ListItem>
              <b>Customer Satisfaction:</b> Ensuring a seamless experience for our
              customers is our top priority. We're committed to meeting and
              exceeding your expectations. From the initial consultation to the
              completion of our services, we prioritize clear communication,
              transparency, and responsiveness, striving to deliver results that
              not only meet but surpass your expectations, and ensuring your
              complete satisfaction with every aspect of our tree care services.
          </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Experience Top-Tier Tree Health Consulting Services in West Valley
          City, UT
        </Heading>
        <Text>
          Contact West Valley Tree Care today at{" "}
          <Link href={SiteData.telLink}>{SiteData.phoneNumberWithDashes}</Link>{" "}
          to schedule your tree health consultation and experience the West
          Valley Tree Care difference!
        </Text>
      </Stack>
    </Box>
  );
};
