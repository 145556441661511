import {
  Box,
  Heading,
  Link,
  ListItem,
  Stack,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import React from "react";
import { SiteData } from "../../Constants/siteData";

export const TreeTrimmingContent = () => {
  return (
    <Box>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>Professional Tree Trimming Services</Heading>
        <Text>
          At West Valley Tree Care, we offer top-notch tree trimming services at
          competitive prices. Here's what we provide:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
              <b>Health Trimming:</b> Our skilled team identifies and trims away
              unhealthy or infested branches, preventing further damage and
              promoting the overall health of the tree. By removing diseased or
              pest-infested branches, we help safeguard the health and vitality
              of your trees, ensuring they can thrive and flourish.
          </ListItem>
          <ListItem>
              <b>Structural Trimming:</b> We trim your trees to encourage a strong
              structure that can withstand storms and prevent branch failure,
              dealing with issues at the root and base. Our structural trimming
              techniques focus on promoting proper branch distribution and
              strengthening the tree's core structure, enhancing its ability to
              endure adverse weather conditions and maintain stability.
          </ListItem>
          <ListItem>
              <b>Aesthetic Trimming:</b> Trees add not just to the environment,
              but also to the visual appeal of your property. We skillfully trim
              your trees to enhance their beauty and uplift your property’s
              aesthetics. Our aesthetic trimming services are tailored to
              accentuate the natural shape and form of your trees, creating a
              picturesque landscape that enhances the overall charm of your
              property.
          </ListItem>
          <ListItem>
              <b>Safety Trimming:</b> We remove branches that pose a threat to
              your property, making sure your landscape remains a safe
              environment. Our safety trimming practices focus on identifying
              and removing overhanging or weak branches that may pose hazards
              to structures, vehicles, or people, ensuring the safety and
              well-being of your property and its occupants.
          </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Why Choose West Valley Tree Care for Your Tree Trimming Needs?
        </Heading>
        <Text>
          With our deep understanding of the West Valley City area and a proven
          track record in tree trimming, here’s why we are your best choice:
        </Text>
        <UnorderedList spacing={3}>
          <ListItem>
              <b>Expert Arborists:</b> Our team of arborists possesses the
              necessary skills and knowledge to trim your trees safely and
              effectively. With extensive training and experience, our arborists
              understand the intricacies of tree trimming techniques and employ
              best practices to ensure the health and vitality of your trees.
          </ListItem>
          <ListItem>
              <b>Quality Service:</b> We are committed to delivering high-quality
              service, and our customer reviews and track record prove it! From
              initial consultation to project completion, we prioritize excellence
              in every aspect of our service delivery, striving to exceed customer
              expectations and provide exceptional results.
          </ListItem>
          <ListItem>
              <b>Local Experts:</b> Our team is well-versed with the local botany,
              shrubs, and landscapes, providing specialized and focused approaches
              you won’t find elsewhere. By leveraging our deep understanding of
              local ecosystems and environmental conditions, we offer tailored
              solutions that address the unique needs and challenges of your
              property, ensuring optimal tree health and landscape aesthetics.
          </ListItem>
          <ListItem>
              <b>Customer Satisfaction:</b> Our number one priority is customer
              satisfaction, and we are committed to making the process as seamless
              as possible for you. From the moment you contact us to the completion
              of the project, we prioritize clear communication, transparency, and
              responsiveness to ensure a positive and stress-free experience for
              every customer.
          </ListItem>
      </UnorderedList>

      </Stack>
      <Stack spacing={3} py={5}>
        <Heading as={"h2"}>
          Premier Tree Trimming Services in West Valley City, UT
        </Heading>
        <Text>
          Get in touch with us today at{" "}
          <Link href={SiteData.telLink}>{SiteData.phoneNumberWithDashes}</Link>{" "}
          to schedule your tree trimming service. Experience the West Valley
          Tree Care difference!
        </Text>
      </Stack>
    </Box>
  );
};
